import type { TBoxSide, TOptional, TOrientation } from '@/types/common';

const SIDES: Record<TOrientation, Record<'end' | 'start', TBoxSide>> = {
  horizontal: {
    end: 'right',
    start: 'left',
  },
  vertical: {
    end: 'bottom',
    start: 'top',
  },
};

const NODE_PROPS = {
  horizontal: {
    coord: 'scrollLeft',
    size: 'offsetWidth',
    space: 'scrollWidth',
  },
  vertical: {
    coord: 'scrollTop',
    size: 'offsetHeight',
    space: 'scrollHeight',
  },
} as const;

const getFreeScrollSpace = (node: HTMLElement, axis: TOrientation): TOptional<Partial<Record<TBoxSide, number>>> => {
  if (node && axis) {
    const props = Object.fromEntries(
      Object.entries(NODE_PROPS[axis]).map<[string, number]>(([name, prop]) => [name, node[prop] as number]),
    );
    const { end, start } = SIDES[axis];
    return {
      [end]: Math.max(0, props.space! - props.size! - props.coord!),
      [start]: Math.max(0, props.coord!),
    };
  }
};

export default getFreeScrollSpace;
