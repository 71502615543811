import { useEffect, useRef, useState } from 'react';

import type { TOptional } from '@/types/common';

export type TSize = {
  height: number;
  width: number;
};

export type TCalculate<T> = (size: TSize) => T;

const useOnWindowResize = <T>(calculate: TCalculate<T>, dependencies?: TOptional<unknown[]>): TOptional<T> => {
  const ref = useRef<TCalculate<T>>(calculate);
  const [value, setValue] = useState<T>();

  useEffect(() => {
    ref.current = calculate;
  }, [calculate]);

  useEffect(() => {
    const listener = () => {
      const { innerHeight, innerWidth, outerHeight, outerWidth } = window;
      const width = Math.min(innerWidth, outerWidth);
      const height = Math.min(innerHeight, outerHeight);
      setValue(ref.current({ height, width }));
    };
    listener();
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [...(dependencies || [])]);

  return value;
};

export default useOnWindowResize;
