import cn from 'classnames';

import Svg from '../svg/port-hole.svg?static';

import styles from './LeaderboardPortHole.module.scss';

type TProps = {
  className?: string;
};

const LeaderboardPortHole = ({ className }: TProps) => (
  <div className={cn(styles.root, className)}>
    <Svg />
  </div>
);

export default LeaderboardPortHole;
