import type { TOptional } from '@/types';

const verifyValue = (
  value: TOptional<string, null>,
  acceptableValues: string[],
  defaultIndex?: TOptional<number>,
): string => {
  const index = value ? acceptableValues.findIndex((item) => value.toLowerCase() === item.toLowerCase()) : -1;
  return acceptableValues[index !== -1 ? index : defaultIndex || 0] || acceptableValues[0]!;
};

export default verifyValue;
