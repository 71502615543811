import cn from 'classnames';

import Svg from '../svg/v-triangle.svg?static';

import styles from './LeaderboardVFrame.module.scss';

type TProps = {
  className?: string;
};

const LeaderboardVFrame = ({ className }: TProps) => (
  <div className={cn(styles.root, className)}>
    <div className={cn(styles.side, '_left')}>
      <Svg />
      <Svg />
      <Svg />
    </div>
    <div className={cn(styles.side, '_right')}>
      <Svg />
      <Svg />
      <Svg />
    </div>
  </div>
);

export default LeaderboardVFrame;
