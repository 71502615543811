import type { TOptional } from '@/types';

export type TNumeric = `${number}`;
export type TNumericKeyed = { [key: TNumeric]: unknown };

const getOrderedNumericKeys = (values: TNumericKeyed, isNotEmpty?: TOptional<boolean>): number[] => {
  if (values) {
    const keys = Object.keys(values) as TNumeric[];
    return (isNotEmpty ? keys.filter((key) => values[key]) : keys).map((item) => +item).sort((a, b) => a - b);
  }
  return [];
};

export default getOrderedNumericKeys;
