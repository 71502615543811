import cn from 'classnames';

import type { TPromoBannerCtaLink, TPromoBannerLeaderboardProps, TWithOnClick } from '@/features/promoBanners/types';
import type { TLink } from '@/infra/types/common';

import ImageSet from '@/components/Media/ImageSet';
import CTA from '@/components/PromoBanner/CTA';
import verifyValue from '@/components/PromoBanner/helpers/verifyValue';

import PortHole from './PortHole';
import VFrame from './VFrame';

import styles from './PromoBannerLeaderboard.module.scss';

export const APPEARANCES = ['standard', 'vFrame', 'portholeFrame'];
export const CONTENT_ALIGNS = ['left', 'center', 'right', 'trueRight'];
export const THEMES = ['lightMode', 'darkMode'];
const OVERLAY_COLORS = ['virginRed', 'oceanBlue', 'rockstar', 'rockstarDark', 'squidInk', 'uplift', 'virginRedDark'];

const REPLACES = {
  align: { trueRight: 'right' },
  text: { right: 'left', trueRight: 'right' },
};

const PromoBannerLeaderboard = ({
  appearance,
  contentAlign,
  cta,
  description,
  imageSet,
  onClick,
  overlayColor,
  theme,
  title,
}: TWithOnClick<TPromoBannerLeaderboardProps>) => {
  const hasImage = !!imageSet?.srcSet;
  const hasCTA = !!cta?.url && !!cta?.label;
  const hasDescription = !!description;
  const verified = {
    appearance: verifyValue(appearance, APPEARANCES),
    contentAlign: verifyValue(contentAlign, CONTENT_ALIGNS),
  };
  const rootClasses = [
    `_appearance-${verified.appearance}`,
    `_align-${REPLACES.align[verified.contentAlign] || verified.contentAlign}`,
    `_text-${REPLACES.text[verified.contentAlign] || verified.contentAlign}`,
    `_theme-${verifyValue(theme, THEMES)}`,
    `_overlay-${verifyValue(overlayColor, OVERLAY_COLORS)}`,
  ];

  // Temporary commented for MSH-123191
  // if (!hasImage) return;

  const Cover = { portholeFrame: PortHole, vFrame: VFrame }[verified.appearance];

  return (
    <div className={cn(styles.root, ...rootClasses)}>
      {hasImage && <ImageSet className={styles.picture} {...imageSet} />}
      {!!Cover && <Cover className={styles.cover} />}
      <div className={cn(styles.content, { _full: hasDescription })}>
        <div className={styles.textual}>
          <h3 className={styles.title}>{title}</h3>
          {hasDescription && <div className={styles.description}>{description}</div>}
        </div>
        {hasCTA && <CTA className={styles.cta} onClick={onClick} {...(cta as TLink & TPromoBannerCtaLink)} />}
      </div>
    </div>
  );
};

export default PromoBannerLeaderboard;
