import type { ExoticComponent, HTMLAttributes, PropsWithChildren, ReactNode, RefAttributes, WheelEvent } from 'react';
import { Children, useCallback, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import isEqual from 'lodash/isEqual';

import getFreeScrollSpace from '@/helpers/getFreeScrollSpace';
import { useIsMobile } from '@/hooks/useIsDevice';
import { type TOrientation } from '@/types/common';

import './styles.scss';

// Use "--grabbable-*" css variables on the parent node(s) to tune the appearance

const WHEEL_FACTOR = 1 / 4;

type TNode = ExoticComponent<PropsWithChildren<HTMLAttributes<HTMLElement> & RefAttributes<HTMLElement>>>;

type TProps = HTMLAttributes<HTMLElement> & {
  children: ReactNode;
  className?: string;
  node?: string;
  sliding?: TOrientation;
  withShadows?: boolean;
};

const Grabbable = ({ children, className, node, sliding, withShadows, ...restRootProps }: TProps) => {
  type TPoint = { left: number; top: number; x: number; y: number };
  const initialPosition = useRef<TPoint>({} as TPoint);
  const ref = useRef<HTMLElement>(null);
  const isMovementAllowed = useRef<boolean>(false);
  const isMovementRegistered = useRef<boolean>(false);
  const [freeSpace, setFreeSpace] = useState<Record<string, boolean>>();

  const hasChildren = !!Children.count(children);

  const onMouseMove = useCallback((event: Event) => {
    if (!isMovementAllowed.current) {
      return;
    }
    const clientX = (event as PointerEvent).clientX ?? (event as TouchEvent).touches?.[0]?.clientX;
    const clientY = (event as PointerEvent).clientY ?? (event as TouchEvent).touches?.[0]?.clientY;
    const dx = clientX - initialPosition.current!.x;
    const dy = clientY - initialPosition.current!.y;
    ref.current!.scrollLeft = initialPosition.current!.left - dx;
    ref.current!.scrollTop = initialPosition.current!.top - dy;
    isMovementRegistered.current = true;
  }, []);

  const onMouseDown = useCallback((event: Event) => {
    isMovementAllowed.current = true;
    event.stopImmediatePropagation();
    initialPosition.current = {
      left: ref.current!.scrollLeft,
      top: ref.current!.scrollTop,
      x: (event as PointerEvent).clientX ?? (event as TouchEvent).touches?.[0]?.clientX,
      y: (event as PointerEvent).clientY ?? (event as TouchEvent).touches?.[0]?.clientY,
    };
  }, []);

  const onClick = useCallback((event: Event) => {
    if (isMovementRegistered.current) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }, []);

  const onMouseUp = useCallback(() => {
    isMovementRegistered.current = false;
    isMovementAllowed.current = false;
  }, []);

  const onWheel = useCallback((event: WheelEvent) => {
    if (!isMovementRegistered.current) {
      const node = ref.current!;
      if (node.scrollWidth <= node.offsetWidth) {
        return;
      }
      event.preventDefault();
      const { deltaX, deltaY } = event;
      node.scrollLeft += WHEEL_FACTOR * (Math.abs(deltaY) > Math.abs(deltaX) ? deltaY : deltaX);
    }
  }, []);

  const isMobileDevice = useIsMobile();
  useEffect(() => {
    const node = ref?.current;
    if (node && hasChildren) {
      const events = {
        click: onClick,
        [isMobileDevice ? 'touchend' : 'mouseup']: onMouseUp,
        [isMobileDevice ? 'touchmove' : 'mousemove']: onMouseMove,
        [isMobileDevice ? 'touchstart' : 'mousedown']: onMouseDown,
        ...(!isMobileDevice && sliding === 'horizontal' ? { wheel: onWheel } : undefined),
        ...(!isMobileDevice ? { mouseleave: onMouseUp } : undefined),
      };
      Object.entries(events).forEach(([event, listener]) => node.addEventListener(event, listener));
      return () => Object.entries(events).forEach(([event, listener]) => node.removeEventListener(event, listener));
    }
  }, [hasChildren, sliding]);

  useEffect(() => {
    const node = ref?.current;
    if (node && hasChildren && withShadows) {
      const updateFreeSpace = () => {
        const scrollSpace = getFreeScrollSpace(node!, sliding || 'vertical');
        if (scrollSpace) {
          const next = Object.fromEntries(Object.entries(scrollSpace).map(([key, value]) => [`_${key}`, value > 0]));
          if (!isEqual(next, freeSpace)) setFreeSpace((prev) => (isEqual(next, prev) ? prev : next));
        }
      };

      updateFreeSpace();
      const listener = () => updateFreeSpace();
      node.addEventListener('scroll', listener);
      return () => node.removeEventListener('scroll', listener);
    }
  }, [hasChildren, withShadows]);

  const Node = (node || 'div') as unknown as TNode;
  return (
    hasChildren && (
      <Node
        {...restRootProps}
        className={!withShadows ? cn('Grabbable', className) : cn('Grabbable__outer', freeSpace)}
        ref={!withShadows ? ref : undefined}
      >
        {!withShadows && children}
        {withShadows && (
          <div className={cn('Grabbable', className)} ref={ref}>
            {children}
          </div>
        )}
      </Node>
    )
  );
};

export default Grabbable;
