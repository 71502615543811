import { useCallback, useRef } from 'react';

import cn from 'classnames';

import Icon from '@publicImages/svg/pause.svg?icon';

import type { TPromoBannerCtaLink, TPromoBannerFeaturetteProps, TWithOnClick } from '@/features/promoBanners/types';
import type { TLink } from '@/infra/types/common';

import ImageSet from '@/components/Media/ImageSet';
import VideoSet, { type TVideoControls } from '@/components/Media/VideoSet';

import CTA from '../CTA';
import Eyebrow from '../Eyebrow';
import verifyValue from '../helpers/verifyValue';

import styles from './PromoBannerFeaturette.module.scss';

const APPEARANCES = ['standard', 'adaLockup'];
const CONTENT_ALIGNS = ['left', 'right'];
const THEMES = ['lightMode', 'darkMode'];
const OVERLAY_COLORS = [
  'virginRedLight',
  'virginRedDark',
  'rockstarLight',
  'rockstarDark',
  'upliftLight',
  'upliftDark',
  'squidInkLight',
  'squidInkDark',
  'oceanBlueLight',
  'oceanBlueDark',
];

const PromoBannerFeaturette = ({
  appearance,
  contentAlign,
  cta,
  description,
  eyebrow,
  imageSet,
  onClick,
  overlayColor,
  theme,
  title,
  videoSet,
}: TWithOnClick<TPromoBannerFeaturetteProps>) => {
  const hasVideo = !!videoSet?.srcSet;
  const hasImage = !!imageSet?.srcSet;
  const hasCTA = !!cta?.url && !!cta?.label;
  const rootClasses = [
    `_appearance-${verifyValue(appearance, APPEARANCES)}`,
    `_align-${verifyValue(contentAlign, CONTENT_ALIGNS)}`,
    `_theme-${verifyValue(theme, THEMES)}`,
    `_overlay-${verifyValue(overlayColor, OVERLAY_COLORS)}`,
  ];

  const refVideoControls = useRef<TVideoControls>();
  const onVideoReady = useCallback((videoControls: TVideoControls) => {
    refVideoControls.current = videoControls;
  }, []);

  const onPlay = useCallback(() => refVideoControls.current?.play(), []);
  const onPause = useCallback(() => refVideoControls.current?.pause(), []);

  // Temporary commented for MSH-123191
  // if (!hasVideo && !hasImage) return;

  const videoFallback = hasImage ? <ImageSet className={styles.picture} {...imageSet} /> : undefined;

  return (
    <div className={cn(styles.root, ...rootClasses)}>
      {hasVideo && (
        <>
          <VideoSet
            autoPlay
            className={styles.video}
            fallback={videoFallback}
            loop
            muted
            onClick={onPlay}
            onReady={onVideoReady}
            srcSet={videoSet.srcSet}
          />
          <Icon className={styles.pause} onClick={onPause} />
        </>
      )}
      {!hasVideo && videoFallback}
      <div className={styles.content}>
        {!!eyebrow && <Eyebrow className={styles.eyebrow} {...eyebrow} isOmitPastCountdown />}
        <div className={styles.textual}>
          <h3 className={styles.title}>{title}</h3>
          <div className={styles.description}>{description}</div>
        </div>
        {hasCTA && <CTA className={styles.cta} onClick={onClick} {...(cta as TLink & TPromoBannerCtaLink)} />}
      </div>
    </div>
  );
};

export default PromoBannerFeaturette;
