import { type ReactNode, type RefObject, useEffect, useRef, useState } from 'react';

import cn from 'classnames';

import type { TMediaSrcSet } from '@/infra/types/common';
import type { TOptional } from '@/types/common';

import useAcceptableSrc, { type TUseAcceptableSrcArgs } from '../hooks/useAcceptableSrc';

export type TVideoControls = {
  load: () => void;
  pause: () => void;
  play: () => void;
};

export const makeVideoControls = (
  ref: RefObject<HTMLMediaElement>,
  setIsPlayed: (value: boolean) => void,
): TVideoControls => ({
  load: () => ref.current?.load(),
  pause: () => {
    ref.current?.pause();
    setIsPlayed(false);
  },
  play: () => {
    ref.current?.play();
    setIsPlayed(true);
  },
});

type TProps = Omit<JSX.IntrinsicElements['video'], 'src'> &
  Pick<TUseAcceptableSrcArgs, 'chooseSize' | 'isScreenBased' | 'sizeThreshold'> & {
    className?: TOptional<string>;
    fallback?: ReactNode;
    onReady?: TOptional<(controls: TVideoControls) => void>;
    srcSet: TMediaSrcSet;
  };

const VideoSet = ({
  autoPlay,
  chooseSize,
  className,
  fallback,
  isScreenBased,
  onReady,
  sizeThreshold,
  srcSet,
  ...restProps
}: TProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const refIsReady = useRef<boolean>();
  const [isPlayed, setIsPlayed] = useState<boolean>(!!autoPlay);
  const args = { chooseSize, containerRef, isScreenBased, sizeThreshold, srcSet };
  const { ref, setRef, src } = useAcceptableSrc<HTMLVideoElement>(args);

  useEffect(() => {
    if (!refIsReady.current) {
      refIsReady.current = true;
      onReady?.(makeVideoControls(ref, setIsPlayed));
    }
  }, [src]);

  return (
    <div className={cn(className, { _playing: isPlayed })} ref={containerRef}>
      {!!src && <video autoPlay={isPlayed} ref={setRef} src={src} {...restProps} playsInline />}
      {!src && fallback}
    </div>
  );
};

export default VideoSet;
