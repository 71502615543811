import { type TMediaSet } from '@/infra/types/common';

import useAcceptableSrc, { type TUseAcceptableSrcArgs } from '../hooks/useAcceptableSrc';

/* Can't just use the "srcSet" attribute for two reasons:
Firstly, when reducing the element size, Google Chrome does not return a smaller image, but uses a larger one because
it has already been loaded.
Secondly, Google Chrome loads images much larger than necessary (with a large margin) and it cannot be controlled */

type TProps = Omit<JSX.IntrinsicElements['img'], 'alt' | 'src' | 'srcSet'> &
  TMediaSet &
  Pick<TUseAcceptableSrcArgs, 'chooseSize' | 'containerRef' | 'isScreenBased' | 'sizeThreshold'>;

const ImageSet = (props: TProps) => {
  const { alt, chooseSize, className, containerRef, isScreenBased, sizeThreshold, srcSet, ...restProps } = props;
  const args = { chooseSize, containerRef, isScreenBased, sizeThreshold, srcSet };
  const { isDisabled, setRef, src } = useAcceptableSrc<HTMLImageElement>(args);
  return !isDisabled && <img {...restProps} alt={alt} className={className} ref={setRef} src={src} />;
};

export default ImageSet;
