import { useMemo } from 'react';

import type { TPromoBannerAny } from '@/features/promoBanners/types';
import type { TOptional } from '@/types/common';

import { selectPromoBanners } from '@/features/promoBanners/selectors';
import { PromoBannerPlaceId, PromoBannerType } from '@/features/promoBanners/types';
import { useAppSelector } from '@/store';

const usePromoBannersProps = (
  placeId: PromoBannerPlaceId,
  type?: TOptional<PromoBannerType>,
): TOptional<TPromoBannerAny> => {
  const all = useAppSelector<TPromoBannerAny[]>(selectPromoBanners);
  return useMemo(() => {
    if (all?.length && placeId) return all.find((item) => (!type || item.type === type) && item.placeId === placeId);
  }, [all, placeId, type]);
};

export default usePromoBannersProps;
